import useSWR from 'swr';

// Components
import SkeletonSectionHeadings from '../../components/SectionHeadings/SkeletonSectionHeadings';
import SectionFeatured from '../../components/SectionFeatured/SectionFeatured';
import SkeletonSectionFeatured from '../../components/SectionFeatured/Skeleton';

// Libraries
import swrUrlParser from '../swrUrlParser/swrUrlParser';
import useSWRFetcher from '../useSWRFetcher';
import getTalkImageSrc from '../getTalkImageSrc';
import gridLink from '../gridLink/gridLink';

export const FetchFeatured = (params, componentInfo) => {
  let apiUrl;
  if (componentInfo?.type == 'series') {
    apiUrl = '/api/series';
  } else if (componentInfo?.type == 'episode') {
    apiUrl = '/api/webcasts';
  } else {
    apiUrl = '/api/summits';
  }
  const fullUrl = swrUrlParser(apiUrl, params);

  const { data, error } = useSWR(fullUrl, useSWRFetcher());

  console.log(data);

  let featuredData;

  if (error) {
    featuredData = {
      data: [],
      found: 0,
    };
  }

  if (
    !data ||
    data['summits']?.length === 0 ||
    data['communications']?.length === 0
  ) {
    featuredData = {
      data: [],
      found: 0,
    };
  } else {
    let setFeatureData;
    if (componentInfo?.type === 'episode') {
      setFeatureData = data['communications'];
    } else {
      setFeatureData = data['summits'];
    }
    featuredData = {
      data: setFeatureData,
      found: data['found'],
    };
  }

  switch (componentInfo?.type) {
    case 'series':
      return (
        <>
          {(() => {
            if (!data) {
              return (
                <>
                  <SkeletonSectionFeatured />
                </>
              );
            } else if (featuredData['data']?.length > 0) {
              // Set series featured grid link.
                const setGridLink = gridLink(
                  null,
                  featuredData['data'][0]['id'],
                  componentInfo?.type,
                  componentInfo?.seriesUTM,
                  'search-featured-section',
                  0,
                  '/series/'
                );

              return (
                <>
                  <SectionFeatured
                    summitData={featuredData['data'][0]}
                    type="series"
                    sectionTitle={componentInfo?.sectionTitle}
                    priorityStatus={componentInfo?.priorityStatus}
                    dataBddTitle={'featured-talk-title-series'}
                    dataBddDesc={'featured-talk-card-description-series'}
                    dataBddLearnMore={'featured-talk-learn-more-series'}
                    imageUrl={featuredData['data'][0]['imageUrl']}
                    gridLink={setGridLink}
                    buttonLabel={'Learn more'}
                  />
                </>
              );
            } else if (featuredData['series']?.length < 1) {
              <></>;
            }
          })()}
        </>
      );
      break;
    case 'summits':
      return (
        <>
          {(() => {
            if (!data) {
              return (
                <>
                  <SkeletonSectionFeatured />
                </>
              );
            } else if (featuredData['data']?.length > 0) {
              // Set summits featured grid link.
              const setGridLink = gridLink(
                featuredData['data'][0]['wordpressLink'],
                featuredData['data'][0]['id'],
                componentInfo?.type,
                componentInfo?.summitsUTM,
                'search-featured-section',
                0,
                componentInfo?.summitUrl + '/summit/'
              );
              return (
                <>
                  <SectionFeatured
                    summitData={featuredData['data'][0]}
                    type="summits"
                    sectionTitle={componentInfo?.sectionTitle}
                    dataBddTitle={'featured-talk-title-summits'}
                    dataBddDesc={'featured-talk-card-description-summits'}
                    dataBddLearnMore={'featured-talk-learn-more-summits'}
                    priorityStatus={componentInfo.priorityStatus}
                    imageUrl={featuredData['data'][0]['imageUrl']}
                    gridLink={setGridLink}
                    buttonLabel={'Learn more'}
                  />
                </>
              );
            } else if (featuredData['data']?.length < 1) {
              <></>;
            }
          })()}
        </>
      );
      break;
    case 'episode':
      return (
        <>
          {(() => {
            if (!data) {
              return (
                <>
                  <SkeletonSectionFeatured />
                </>
              );
            } else if (featuredData['data']?.length > 0) {
              // UTM params for featured episode.
              const featuredEpisodeUTM = `?utm_source=brighttalk-portal&utm_medium=web&utm_content=featured&utm_campaign=all-series&channel_id=${featuredData?.['data']?.[0]?.['channel']?.id}`;

              // Set series feature episode link.
              const setGridLink =
                featuredData?.['data']?.[0]?.['url'] + featuredEpisodeUTM;
              return (
                <SectionFeatured
                  summitData={featuredData['data'][0]}
                  type="episode"
                  sectionTitle={componentInfo?.sectionTitle}
                  dataBddTitle={'featured-talk-title-summits'}
                  dataBddDesc={'featured-talk-card-description-summits'}
                  dataBddLearnMore={'featured-talk-learn-more-summits'}
                  priorityStatus={componentInfo.priorityStatus}
                  imageUrl={getTalkImageSrc(
                    featuredData?.['data']?.[0]?.['links']
                  )}
                  gridLink={setGridLink}
                  buttonLabel={'Register'}
                />
              );
            } else if (featuredData['data']?.length < 1) {
              <></>;
            }
          })()}
        </>
      );
      break;
  }
};

export default FetchFeatured;
